// 新闻列表
<template>
  <div class="news-lists-content">
    <el-row class="news-list">
      <el-col :span="24" v-for="item in news" :key="item.id">
        <router-link :to="{path:'/news/newsDetails',query:{type:$route.name,id:item.id}}"
          class="news-item justify-between">
          <div class="news-img flex-center">
            <el-image :src="$base+(item.title_url?item.title_url:getConfigs.logo)"></el-image>
          </div>
          <div class="news-info align-center flex-warp">
            <p class="justify-between align-center">
              <span class="news-title text-ellipsis">{{item.biaoti}}</span>
              <span class="news-time">{{item.created_at|filterTime}}</span>
            </p>
            <p class="news-text  text-ellipsis-lines"> {{item.sy_title}}</p>
          </div>
        </router-link>
      </el-col>
    </el-row>
    <Paginations v-if="total" :isAll="true" :total="total" :pageSize="pageSize" @changePage="changePage" />
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import Paginations from '@/components/Paginations.vue'
import { getNews } from '@/api'
export default {
  components: {
    Paginations
  },
  data () {
    return {
      news: [],
      total: 0,// 总条数
      pageSize: 7, // 每页展示多少条
    };
  },
  computed: {
    ...mapGetters(['getConfigs'])
  },

  mounted () {
    this.getNews(this.$route.name, 1)
  },
  methods: {
    async getNews (type, page) {
      try {
        let newsRes = await getNews({ type, page })
        this.news = newsRes.data.data
        if (this.news.length) {
          this.total = newsRes.data.num
          this.pageSize = newsRes.data.page_size
        } else {
          this.total = 0
        }
      } catch (error) {

      }
    },
    changePage (page) {
      this.getNews(this.$route.name, page)
    }
  },

};
</script>

<style scoped lang="less">
.news-list {
  min-height: 500px;
}
.news-item {
  padding: 20px 0;
  border-bottom: 1px solid #cccccc;
  .news-img {
    width: 18%;
    height: 90px;
    max-height: 90px;
    margin-right: 22px;
    /deep/ .el-image {
      img {
        max-height: 90px;
      }
    }
  }
  .news-info {
    min-width: 79%;
    flex: 1;
    align-items: center;
    p {
      width: 100%;
      height: 20px;
      line-height: 20px;
    }
    .news-title {
      width: 85%;
      font-size: 18px;
      font-weight: bold;
    }
    .news-time {
      font-size: 14px;
      color: #999999;
    }
    .news-text {
      color: #666666;
      font-size: 14px;
      -webkit-line-clamp: 1;
    }
  }
}
</style>